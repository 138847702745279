<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-progress-circular indeterminate size="16" width="2" color="grey darken-2" v-if="!isViewReady"/>
            <template v-if="checkEmail">
                <template v-if="brandprofile">
                    <BrandImage :alias="brandprofile" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
                    <!-- <p class="text-center">{{ brandprofile }}</p> -->
                </template>
                <!-- <template v-if="profileRequest.domain">
                    <p class="text-center">{{ profileRequest.domain }}</p>
                </template> -->
                <BrandCard class="mb-6">
                    <template #title>
                        Verify email
                    </template>
                    <!-- <v-toolbar dense flat color="blue darken-2" dark>
                        <v-toolbar-title>Verify email</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar> -->
                    <v-card-text>
                        <p>Check your inbox to continue.</p>
                        <!-- TODO: we should show the subject line of the email , like "[brand] email verification" and the email address we're sending it from, to help users find it; this info should be available from the server since it just sent the message.  we can show it here in a table like we're showing an email, with "from", "subject", "to" and put the pencil icon next to the "to" value if the user wants to edit,  instead of a readonly form field.   we can even show a message body area with a label "look for this message in your inbox or spam folder". -->
                    </v-card-text>
                    <v-form @submit.prevent="switchAccount" onSubmit="return false;" @keyup.enter.native.prevent="switchAccount" class="mx-4 pb-6">
                        <v-text-field
                            :value="email"
                            label="Email"
                            :color="primaryColor"
                            disabled
                            readonly
                        >
                            <template #append-outer>
                                <v-tooltip top v-if="editRoute">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :color="primaryColor" @click="switchAccount()" v-on="on">
                                            <span :color="primaryColor">
                                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span>Edit email address</span>
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-form>
                </BrandCard>
                <v-alert border="left" :color="primaryColor" colored-border class="mt-8"> <!-- previously color="blue darken-2"  -->
                    <!-- <p class="ma-0 pa-0">Check your inbox for a link to continue.</p> -->
                    <p class="mx-0 mb-0 pa-0 grey--text text--darken-1 font-weight-light">If you don't receive an email, check that the email you entered is correct. Also, some email providers mistake the verification email for spam, so if it&apos;s not in the inbox, please check the spam folder.</p>
                </v-alert>
            </template>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
// import { isValidEmail } from '@/sdk/input';
// import BrandList from '@/components/BrandList.vue';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        // BrandList,
        BrandCard,
        BrandImage,
    },
    data: () => ({
        isViewReady: false,
        interactionId: null,
        interaction: null,
        status: null,
        duration: null,
        email: '',
        brandprofile: null,
        editRoute: null,
        submitTimestamp: null,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            focus: (state) => state.focus,
            brand: (state) => state.brand,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        checkEmail() {
            return this.status === 'check_email';
        },
        title() {
            let title;
            if (this.interaction?.title) {
                switch (this.interaction.title) {
                case 'login':
                    title = 'Sign in';
                    break;
                case 'signup':
                    title = 'Sign up';
                    break;
                case 'register':
                    title = 'Register';
                    break;
                case 'subscribe':
                    title = 'Subscribe';
                    break;
                case 'verify_email':
                default:
                    title = 'Verify email address';
                    break;
                }
            } else {
                title = 'Verify email address';
            }
            return title;
        },
    },
    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
    },
    methods: {
        reset() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.status = null;
            this.duration = null;
            this.verificationExpires = null;
        },
        async loadBrand(brandprofile) {
            await this.$store.dispatch('loadBrand', { alias: brandprofile, mode: 'light', media_type: 'screen' });
            await this.$store.dispatch('loadPalette', { alias: brandprofile, mode: 'light', media_type: 'screen' });
            this.brandprofile = brandprofile;
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`response: ${JSON.stringify(response)}`); // {"type":"verify_email","content":{"antiphishing":true,"email":"jonathan@buhacoff.net","intent":"signup","intent_data":{"username":"jonathan@buhacoff.net"},"brandprofile":"etherlink", "status": "new", "status_on": 1655086282382},"not_after":1655086282382}
                const { type, content, duration } = response;
                if (type !== 'verify_email') {
                    this.forbiddenError = true; // TODO: should we have a different error for "you got here with an invalid interaction, its not even the right type" ?
                    // we don't do a timeout for this because this error is not recoverable on this page... user would have to go back to previous page to fix it
                    // this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.duration = duration; // TODO: show in UI that user has X time to click on verification email ;  this duration is in millis, need to round to nearest minute then convert to text like "the verification email will expire in about 15 minutes"
                const {
                    email,
                    brandprofile,
                    status,
                    edit_route: editRoute,
                } = content;
                this.email = email;
                if (brandprofile) {
                    this.loadBrand(brandprofile);
                }
                this.status = status;
                if (status === 'new') {
                    // ask server to send verification email now
                    this.sendVerificationEmail();
                }
                this.editRoute = editRoute; // either null or a relative URL where user can edit the email address; if null then email address is not editable
                // TODO: schedule status check for duration remaining, every 1 minute; so if this page is still open when interaction expires we would then set sthe status to expired, and set the epxired error
            } catch (err) {
                console.error('failed to load interaction', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        // this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
                this.isViewReady = true;
            }
        },
        async sendVerificationEmail(resend = false) {
            try {
                this.$store.commit('loading', { sendVerificationEmail: true });
                const response = await this.$client.main().authn.verifyEmail({ email: this.email, interactionId: this.interactionId, resend });
                /*
                console.log(`response: ${JSON.stringify(response)}`); // {"type":"verify_email","content":{"antiphishing":true,"email":"jonathan@buhacoff.net","intent":"signup","intent_data":{"username":"jonathan@buhacoff.net"},"brandprofile":"etherlink", "status": "new", "status_on": 1655086282382},"not_after":1655086282382}
                const { type, content, not_after } = response;
                if (type !== 'verify_email') {
                    this.forbiddenError = true; // TODO: should we have a different error for "you got here with an invalid interaction, its not even the right type" ?
                    // we don't do a timeout for this because this error is not recoverable on this page... user would have to go back to previous page to fix it
                    // this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    return;
                }
                const { brandprofile, status } = content;
                this.brandprofile = brandprofile; // start loading brand colors and logos
                this.status = status;
                if (status === 'new') {
                    // ask server to send verification email now
                    this.sendVerificationEmail();
                }
                */
                // if (response.status === 'check_email')
                const { status, duration } = response;
                this.status = status;
                this.duration = duration; // TODO: show in UI that user has X time to click on verification email ;  this duration is in millis, need to round to nearest minute then convert to text like "the verification email will expire in about 15 minutes"

                if (status === 'check_email') {
                    if (Number.isInteger(duration)) {
                        this.verificationExpires = toText(duration);
                    } else {
                        this.verificationExpires = null;
                    }
                }
            } catch (err) {
                console.error('failed to load interaction', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        // this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { sendVerificationEmail: false });
                this.isViewReady = true;
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchAccount() {
            // user cannot edit email address on this form, they have to go back to previous page
            // this.reset();
            // this.$nextTick(() => {
            //     setTimeout(() => { this.activate('usernameInput'); }, 1);
            // });
            if (this.editRoute) {
                this.$router.push(this.editRoute); // for example '/login?email=sparky@example.com'
            }
            // window.history.back(); // TODO: instead of relying on the browser, we should get the "prev_route" from the interaction and go "forward" there.
        },
    },
    mounted() {
        /*
        this.$nextTick(() => {
            setTimeout(() => { this.activate('usernameInput'); }, 1);
        });
        */
        // the first time user arrives on this page, there would not be an interaction id in the query,
        // but there could be an email address to pre-fill;
        // the second time the user arrives on this page (after email verification is completed), there
        // would be an interaction id also;
        // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
        this.interactionId = this.$route.query.i;
        /*
        this.username = this.$route.query.email ?? '';
        if (this.interactionId && this.username) {
            this.signup();
        }
        */
        if (this.interactionId) {
            this.loadInteraction();
        }
        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
    },
};
</script>
